.App {
  padding-top: 3em;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  height: 100%;
}

.navigationText {
  padding-left: 1em;
}

.formLabel {
  display: flex;
  margin: auto;
  justify-content: center;
  font-size: 23px;
}

.stars {
  display: flex;
  margin: auto;
  width: 100%;
  justify-content: center;
}

.commentBox {
  display: flex;
  margin: auto;
  width: 40%;
  font-size: 23px;
  border-radius: 3px;
}

button {
  display: flex;
  margin: auto;
  margin-top: 1em;
  padding: 0.4em;
  border-radius: 3px;
  font-size: 23px;
}
